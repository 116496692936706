import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import VModal from 'vue-js-modal';
import VueMask from 'v-mask';
//import initPushService from '@/services/PushFirebase';
import initFirbase from '@/services/firebase';
import YmapPlugin from 'vue-yandex-maps'


//initPushService();
initFirbase()

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(VModal).use(VueMask)
const settings = { 
  apiKey: '1d14299a-6fd8-4f34-8be3-c3644edc330d',
  lang: 'ru_RU',
  version: '2.1'
}
Vue.use(YmapPlugin, settings)



