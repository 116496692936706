<template>
  <div class="card">
    <div class="card__wrapper">
      <section class="card__item card__item_no">
        <CardItem
          v-for="item in productsInfo.plots"
          :key="item.index"
          :cardInfo="item['@id']"
          @back="setArray"
        />
      </section>
      <section class="card__item">
        <CardItemAfterSort
          v-for="item in sortPlots"
          :key="item.index"
          :cardInfo="item"
        />
      </section>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/axios.js";
import CardItem from "@/components/cards/CardItem.vue";
import CardItemAfterSort from "@/components/cards/CardItemAfterSort.vue";
export default {
  name: "ProductCard",
  components: {
    CardItem,
    CardItemAfterSort,
  },
  data(){
    return {
      arr: [],
      id: '',
    }
  },
  computed: {
    sortPlots() {
      return this.arr
      .filter((a) => a.isActive)
      .sort((a, b) => a.sort - b.sort);
    }
  },
  watch: {
    productsInfo() {
      this.clian()
      this.setId()
    }
  },

  methods: {
    setId() {
      this.id = this.productsInfo['@id']
    },
    clian() {
      if(this.id !== this.productsInfo['@id']) {
        this.arr = []
      }

    },
    setArray(a) {
      this.arr.push(a)
    }
  },
  props: {
    productsInfo: {
      default: () => {},
      type: Object,
    },
  },
};
</script>
<style>
.card {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.card__item {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.card__heading {
  margin: 0;
}
.card:last-child {
  margin-bottom: 80px;
}
.product {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  align-content: center;
  text-align: center;

  border-radius: 15px;

  background: black;
  background-image: url(../../assets/card_img.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
}
.product__item {
  margin: 0;
  padding: 10px;
  width: 100;
  height: 60px;
  align-content: center;
}
.product__name {
  font-size: large;
}
.product__heading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.product__item:nth-child(3) {
  border-bottom-left-radius: 10px;
}
.product__item:nth-child(5) {
  border-bottom-right-radius: 10px;
}
.product__info {
  margin: 0;
  font-size: large;
}
.product__size {
  grid-column-start: 3;
}
.product__smalltext {
  color: rgb(156, 147, 147);
}

.second_rows {
  background-color: #f6f6f6;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}
.card__item_no {
  display: none;
}
</style>
