<template>
  <div class="point">
    <button class="point__wrapper" v-on:click="show = !show">
      <div class="point-item">
        <div class="point__samll-info">
          <h1 v-if="cardInfo.visibleTitle">{{ cardInfo.visibleTitle }}</h1>
          <h1 v-if="!cardInfo.visibleTitle">{{ cardInfo.title }}</h1>
          <svg
            v-if="!show"
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13 7L7 1L1 7" stroke="#c6890d" />
          </svg>
          <svg
            v-if="show"
            class="acive__icon"
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13 7L7 1L1 7" stroke="#c6890d" />
          </svg>
        </div>
        <div class="point__more-info">
          <div class="point__open-info">
            <h2>{{ cardInfo.autoTotal }}</h2>
            <p class="point__text">Кол-во машин</p>
          </div>
          <hr class="hr-line" />
          <div class="point__open-info">
            <h2>{{ cardInfo.expectation }}</h2>
            <p class="point__text">Время ожидания</p>
          </div>
        </div>
        <hr class="hr-botton" />
      </div>
      <transition name="fade">
        <div class="point-item--active" v-if="show">
          <div class="point-item__wrapper" v-show="cardInfo.products.length">
            <div
              class="active__elem"
              v-for="item in cardInfo.products"
              :key="item.index"
              
            >
            <div v-if="item.isActive">
              <div class="product-info">
                <h3 class="product-info__heading" v-if="item.visibleTitle">{{ item.visibleTitle }}</h3>
                <h3 class="product-info__heading" v-if="!item.visibleTitle">{{ item.title }}</h3>
                <p class="product-info__price">{{ item.price }}₽</p>
              </div>

              <div class="shipment" v-if="item.shipmentStatus">
                <p class="shipment__text">Состояние отгрузки</p>
                <p class="shipment__ans">Отгружается</p>
              </div>
              <div v-else class="shipment">
                <div class="shipment__item">
                  <p class="shipment__text">Состояние отгрузки</p>
                  <p class="shipment__ans">Не отгружается</p>
                </div>
                <div class="shipment__item">
                  <p class="shipment__text">Возобновление отгрузки</p>
                  <p class="shipment__ans">{{ item.shipmentResumption }}</p>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </button>
  </div>
</template>
<script>
import axios from "@/axios/axios.js";
export default {
  name: "CardItemAfterSort",
  props: {
    cardInfo: {
      default: () => "",
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      currData: {},
    };
  },
  async created() {

  },
  
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.point-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-self: center;
}
.point__samll-info {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
.point__more-info {
  background-color: #fafafa;
  border-radius: 15px;

  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}
.point__open-info {
  text-align: left;
}
.point__open-info h2 {
  margin-bottom: 7px;
}
.point__open-info p {
  margin-top: 0;
}
.hr-line {
  border: 1px solid #e2e0e0;
  margin: 5px;
  height: 35px;
}
.hr-botton {
  width: 100%;
  border: 1px solid #e2e0e0;
  margin-top: 20px;
  margin-bottom: 0px;
}
.point-item--active {
  width: 100%;
}
.acive__icon {
  transform: rotate(180deg);
}
.point__wrapper {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  border-top-left-radius: 20px 20px;
  border-top-right-radius: 20px 20px;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.product-info {
  background: linear-gradient(
    0deg,
    rgba(37, 37, 37, 0.8),
    rgba(37, 37, 37, 0.8)
  );

  background-image: url(../../assets/productBG.png);
  background-repeat: no-repeat;
  background-size: cover;

  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: center;
  align-self: center;
  align-items: center;
  padding: 15px;
  padding-top: 12px;
  border-top-left-radius: 20px 20px;
  border-top-right-radius: 20px 20px;
}
.active__elem {
  background: #fafafa;
  margin-top: 30px;
  margin-bottom: 30px;
  border-top-left-radius: 20px 20px;
  border-top-right-radius: 20px 20px;
  border-bottom-left-radius: 20px 20px;
  border-bottom-right-radius: 20px 20px;
}
.shipment__ans {
  font-weight: 600;
  margin-top: 2px;
  padding-bottom: 10px;
}
.shipment__text {
  color: #8d8c8b;
  margin-bottom: 0px;
}
.product-info__price {
  font-weight: 600;
}
.point__text {
  color: #545251;
}
</style>
