<template>
  <div class="address">
    <section class="error" v-if="errored">
      <p class="error__text">Ничего не найдено!</p>
    </section>
    <section class="address__item" v-else>
      <div class="load-info" v-if="loading">
        <div class="loader" title="loading">
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            style="enable-background: new 0 0 50 50"
            xml:space="preserve"
          >
            <path
              fill="#000"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      </div>

      <div class="address-info" v-else>
        <div class="address-info__wrapper">
          <p class="address-info__heading">Адрес</p>
          <p class="address-info__text">{{ addressInfo.value }}</p>
          <a
            class="address-info__link"
            target="_blank"
            :href="link.value"
            >Открыть в навигаторе</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "@/axios/axios.js";
export default {
  name: "AddressInfo",
  data() {
    return {
      addressInfo: {},
      loading: true,
      errored: false,
      link: '',
    };
  },
  created() {
    this.getAddressInfo();
    this.getLink()
  },
  methods: {
    getAddressInfo() {
      axios
        .get("api/key_values/5")
        .then((res) => {
          this.addressInfo = res.data;
          console.log(this.addressInfo)
        })
        .catch((error) => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getLink() {
      axios
        .get("api/key_values/8")
        .then((res) => {
          this.link = res.data;
          console.log(this.link)
        })
        .catch((error) => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },

  },
};
</script>

<style scoped>
.address {
  border: 2px solid #f6f6f6;
  border-radius: 12px;
  margin-top: 20px;
}
.address-info__wrapper > p {
  margin-left: 10px;
}
.address-info__heading {
  color: #828991;
}
.address-info__text {
  font-weight: bold;
  margin: 10px 10px 10px 0;
}
.address-info__link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 12px;
  margin: 15px 10px 10px 10px;

  color: white;

  background-color: #c6890d;
  border-radius: 18px;
  font-weight: bold;
  font-size: 16px;
}
.address-info__link:visited {
  color: white;
}
.error__text {
  margin: 5px;
}
.load-info__text {
  margin: 10px;
}
.load-info {
  text-align: center;
}
</style>
