<template>
    <div class="feed-info__wrapper" @click="showFeed = !showFeed">
        <h3 class="feed-info__heading">
            {{ feedInfo.title }}
            <svg
                    v-if="showFeed"
                    class="acive__icon"
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M13 7L7 1L1 7" stroke="#d0253e"/>
            </svg>
            <svg
                    v-if="!showFeed"
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M13 7L7 1L1 7" stroke="#d0253e"/>
            </svg>
        </h3>

        <div style="margin-top: 10px" v-if="feedInfo?.createAt" v-text="moment(feedInfo?.createAt)"></div>
        <div v-show="!showFeed" v-if="feedInfo?.shortDescription" v-html="`${feedInfo.shortDescription}`"></div>
        <transition name="fade">
            <div>
                <div
                        v-show="showFeed"
                        class="feed-info__description"
                        v-html="`${feedInfo.description}`"
                        v-if="feedInfo?.description"
                ></div>
                <img
                        v-show="showFeed && currBookInfoCont?.preview?.path"
                        class="handBookCard__image"
                        :src="`https://sibcoalapi.jslab.ru/${currBookInfoCont?.preview?.path}`"
                        alt="handbook image"
                />
            </div>
        </transition>

    </div>
</template>

<script>
import axios from "@/axios/axios.js";
import moment from "moment";

export default {
    name: "FeedItem",
    data() {
        return {
            showFeed: false,
            currBookInfo: {},
            loading: true,
            errored: false,
        };
    },
    props: {
        feedInfo: {},
        openId: {type: Number, default: 0}
    },
    created() {
        this.getHandBookCardInfo();
    },
    methods: {
        moment: function (data) {
            return moment(data).locale('ru').format('DD-MM-YYYY, HH:mm');
        },
        getHandBookCardInfo() {
            console.log(this.feedInfo)
            axios
                .get(`${this.feedInfo['@id']}`, {
                    headers: {

                        "Accept": "application/json"
                    },
                })
                .then((res) => {
                    this.currBookInfo = res.data;
                    console.log(this.currBookInfo)
                    if (Number(this.currBookInfo.id) === Number(this.openId)) {

                        this.showFeed = true
                        //  alert(this.showFeed)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        },
    },
    computed: {

        currBookInfoCont() {
            console.log(this.currBookInfo)
            return this.currBookInfo
        }
    },
};
</script>

<style>
.feed-info__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.acive__icon {
    transform: rotate(180deg);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
    opacity: 0;
}

.feed-info__heading {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.feed__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.feed-info:first-child {
    margin-top: 20px;
}

.feed-info:last-child {
    margin-bottom: 80px;
}
</style>
