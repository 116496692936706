<template>
  <div class="jobs">
    <h1 class="job__heading">Работа в Сибугле</h1>
    <div class="resume">
      <div class="resume__info">
        <h3 class="resume__heading">Отправить резюме</h3>
        <small class="resume__text">Будет доставлено в отдел кадров</small>
      </div>
      <div class="resume-input__wrapper">
        <label class="resume-input__label" for="inputResume">

          <img
            class="resume-input__img"
            src="../assets/addfile-green.svg"
            alt="addfile"
            v-if="successUploadFile"
          />
          <img
            class="resume-input__img"
            src="../assets/addfile.svg"
            alt="addfile"
            v-else
          />

          <p class="resume-input__text">Выбрать файл</p>
          <input
            class="resume-input"
            id="inputResume"
            type="file"
            name="resume"
            ref="file"
            accept=".xml, .doc, .docx, application/pdf"
            v-on:change="handleFileUpload()"
          />
          <div class="file">
            <p class="file__name">{{ file.name }}</p>
          </div>
          <span id="fileName"></span>
        </label>
        <button v-on:click="submitFile()" class="resume__button" type="submit">
          Отправить файл
        </button>
        <modal :width="310" class="success-modal" name="file-modal">
            <div class="cross" @click="hideModal"></div>
            <p><span><img src="../assets/check.svg" alt="img"></span>Резюме отправлено</p>
        </modal>
        <modal :width="310" class="success-modal" name="uploadfile-modal">
            <div class="cross" @click="hideModal"></div>
            <p>Прикрепите файл</p>
        </modal>
        <!--<p v-show="successSentResume" class="success-info">Резюме отправлено</p> -->
      </div>
    </div>
    <div class="questionnaire">
      <div class="questionnaire__info">
        <h3 class="questionnaire__heading">
          Заполните анкету, если нет резюме
        </h3>
        <small class="questionnaire__text">Анкета для приема на работу</small>
      </div>
      <form @submit.prevent="submitQuestionnaire" class="questionnaire-input">
        <input
          v-model="userFormData.name"
          class="questionnaire-input__name questionnaire-input__item"
          @blur="isNameTouched = true"
          :class="{noValid: isNameError}"
          type="text"
          name="name"
          id=""
          placeholder="Ваше имя"
          required
        />

        <input
          v-model="userFormData.position"
          class="questionnaire-input__position questionnaire-input__item"
          @blur="isPositionTouched = true"
          :class="{noValid: isPositionError}"
          type="text"
          name="position"
          id=""

          placeholder="Желаемая должность"
          required
        />
        <input
          v-model="userFormData.phone"
          class="questionnaire-input__number questionnaire-input__item"
          @blur="isPhoneTouched = true"
          :class="{noValid: isPhoneError}"
          type="tel"
          name="number"
          id=""
          v-mask="'+# (###) ###-####'"
          placeholder="Номер телефона"
          required

        />
        <button class="questionnaire__button" type="submit">
          Отправить анкету
        </button>
        <modal :width="310" class="success-modal" name="success-modal">
            <div class="cross" @click="hideModal"></div>
            <p><span><img src="../assets/check.svg" alt="img"></span>Анкета отправлена</p>
        </modal>
     <!--   <p v-show="successSentQuestionnaire" class="success-info">
          Анкета отправлена
        </p> -->
      </form>
    </div>
    <div class="job-info">
      <h3 class="job-info__heading">Дополнительная информация</h3>
      <a class="job-info__link" href="https://vk.com/sibugol_official"></a>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/axios.js";
export default {
  name: "JobsView",
  data() {
    return {
      successSentResume: false,
      successSentQuestionnaire: false,
      file: "",
      userFormData: {
        name: "",
        position: "",
        phone: "",
      },

      isNameTouched: false,
      isPositionTouched: false,
      isPhoneTouched: false,
      successUploadFile: false,
    };
  },
 /* validations () {
    return {
      name: { required,
        minLength: minLength(3),
        alpha: val => val },
      position: { required,
        alpha: val => val },
      phone: { required,
        alpha: val => val },
    }
  }, */
  created() {},
  methods: {
    hideModal () {
    this.$modal.hide('success-modal');
    this.$modal.hide('file-modal');
    this.$modal.hide('uploadfile-modal');
  },
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      if(this.successUploadFile) {
        axios
        .post("api/files", formData, {
          headers: {
    "Content-Type": "multipart/form-data",
    "Accept": "application/json"
  },
        })
        .then((response) => {
          axios
              .post("api/resumes", {
                file: '/api/files/'+ response.data.id,
              })
              .then(() => {

              })
              .catch(() => {
              });

          console.log("SUCCESS!!");
          this.$modal.show('file-modal');
          this.successUploadFile = false;
          this.file = ''
         // this.successSentResume = true;
        })
        .catch(() => {
          console.log("Error!!");
        });
      } else {
        this.$modal.show('uploadfile-modal');
      }


    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.successUploadFile = true;
      console.log(this.file);
    },
    submitQuestionnaire() {


        axios
        .post("api/resumes", {
          name: this.userFormData.name,
          position: this.userFormData.position,
          phone: this.userFormData.phone
        })
        .then(() => {
          console.log("SUCCESS!!");
          this.$modal.show('success-modal');
         // this.successSentQuestionnaire = true;
        })
        .catch(() => {
          console.log("Error!!");
        });





    },
    inputValidate() {
       // this.v$.$validate()
      }
  },
  computed: {

      isNameValid() {
      return this.userFormData.name !== '';
    },

    isNameError() {
      return !this.isNameValid && this.isNameTouched;
    },
    isPositionValid() {
      return this.userFormData.position !== '';
    },

    isPositionError() {
      return !this.isPositionValid && this.isPositionTouched;
    },
    isPhoneValid() {
      return this.userFormData.phone !== '';
    },

    isPhoneError() {
      return !this.isPhoneValid && this.isPhoneTouched;
    },
  },
  watch: {

  }
};
</script>

<style scoped>
.jobs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.success-modal {
}
.success-modal .cross {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 14px;
  height: 14px;
  cursor: pointer;

}
.noValid {
  border: 1px solid red !important;
}
.success-modal .cross:before {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  height: 1px;
  background: black;
  transform: rotate(45deg);
  top: 7px;
  left: 0px;
}
.success-modal .cross:after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  height: 1px;
  background: black;
  transform: rotate(-45deg);
  top: 7px;
  left: 0px;
}
.jobs:last-child {
  margin-bottom: 80px;
}
.job__heading {
  margin: 10px 0 0 0;
}
/* resume */
.resume {
  border: 2px solid #f6f6f6;
  border-radius: 12px;
}
.resume__info {
  margin: 15px;
}
.resume__heading {
  margin: 0 0 5px 0;
}
.resume__text {
  color: #5d656c;
}
.resume-input {
  display: none;
}
.resume-input__label {
  cursor: pointer;

  border: #d6cfcf dashed 2px;

  border-radius: 15px;
  display: block;
  height: 300px;
  position: relative;
}
.resume-input__wrapper {
  margin: 15px;
}
.resume-input__text {
  position: absolute;
  top: 150px;
  left: calc(50% - 60px);
  color: #18222c;
  font-weight: 600;
}
.resume-input__img {
  position: absolute;
  top: 100px;
  left: calc(50% - 29px);
  width: 50px;
  height: 50px;
}
.resume__button {
  border-radius: 18px;
  background-color: #8d8c8b;
  border-color: transparent;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;

  display: block;
  padding: 16px 12px;
  width: 100%;
  margin: 15px auto;
  cursor: pointer;
}
.file {
  position: absolute;
  top: 180px;
  width: 100%;

}
.file__name {
  width: 100%;
  text-align: center
}
/* questionnaire */
.questionnaire {
  border: 2px solid #f6f6f6;
  border-radius: 12px;
}
.questionnaire__info {
  margin: 15px;
}
.questionnaire__text {
  color: #5d656c;
}
.questionnaire__heading {
  margin: 0 0 5px 0;
}
.questionnaire-input {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin: 15px;
}
.questionnaire-input__item {
  padding: 15px;
  outline: none;
  border: none;
  background-color: #fafafa;
  border-radius: 15px;
}
.questionnaire__button {
  border-radius: 18px;
  background-color: #8d8c8b;
  border-color: transparent;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;

  display: block;
  padding: 16px 12px;
  width: 100%;
  margin: 15px auto;
  cursor: pointer;
}
.success-info {
  color: green;
}
/* job info */
.job-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  border: 2px solid #f6f6f6;
  border-radius: 12px;
}
.job-info__heading {
  width: 200px;
  margin-left: 15px;
}
.job-info__link {
  display: block;
  background-color: #ffbe37;
  background-image: url(../assets/notrealvk.svg);
  background-repeat: no-repeat;
  height: 44px;
  width: 44px;
  margin: 15px;
  border-radius: 18px;
}
</style>
