import axios from '@/axios/axios'
function processPush(message){
 // alert(JSON.stringify(message))
  var data = message['data'];
 // alert(JSON.stringify(data))
   if(data['type'] === 'post'){
       window.location.href = `https://pril.sibugol.com/#/feed/?tab=Feed&id=${data['id']}`
   }
   if(data['type'] === 'notify'){
       window.location.href = `https://pril.sibugol.com/#/feed/?tab=Notifications&id=${data['id']}`
   }
}



function processToken(token){
// Делаем запрос на отправку токена бэку, чтобы он смог отправлять пуши данному пользователю

axios.post("api/cloud_messagings", {

    'token': token,

},{
  headers: {

"Accept": "application/json",
'Content-Type': 'application/ld+json'
},
})
.then(() => {
//  alert("SUCCESS!!")
  console.log("SUCCESS!!");
})
.catch((e) => {
 // alert(e)
  console.log("Error!!");
});
}

function initFirbase(){
  (async function() {

// window._firebaseExists - ключ, который может появиться на странице в самом начале, если он есть - api firebase может появиться (оно появляетя асинхронно, и мы ждем его через setTimeout, а данный ключ нужен для оптимизации, чтобы js не работал вхолостую, если запрос не из приложения)
      if(!window._firebaseExists){
// Зашли не из приложения - ничего не делаем
          return;
      }

      function sleepPromise(ms){
          return new Promise(r => {
              setTimeout(r, ms);
          })
      }

      function waitFirebase(total, interval){
          return new Promise( async (res, rej) => {

              while(true){
// По хорошему - желательно, сначала проверить наличие в циклие, только потом - спать или реджектить.
// Спим interval ms
                  await sleepPromise(interval);

// Проверяем - появилось ли firebase
                  if((typeof Firebase) !== 'undefined'){
// Появилось
                      return res();
                  }

// Отнимаем от общего ожидаемого времени, если время истекло - возвращаем reject, иначе - еще 1 итерация
                  total -= interval;

                  if(total < 0){
                      return rej();
                  }
              }
          });
      }

      try{
// Ожидаем готовности api (2 секунды, с проверкой наличия, каждую секунду, потом, можно поднастроить)
          await waitFirebase(1000 * 2, 1000)
      }catch(e){
// Не дождались api. Практически - такого не может быть, теоретически - возможно.
          console.log('Firebase wait timeouted!');
          return;
      }

      try {
          await Firebase.requestUserPermission();
      } catch(e) {
          return;
      }

      Firebase.getToken().then(processToken);
      Firebase.onTokenRefresh(processToken);

      Firebase.onMessage(processPush);
  })();
}

export default initFirbase;



