<template>
  <div class="home">
    <CardList />
  </div>
</template>

<script>
import CardList from "@/components/CardList.vue";

export default {
  name: "HomeView",
  components: {
    CardList,
  },
};
</script>
