<template>
  <div class="handbook">
    <h1 class="handbook__heading">Справочник</h1>
    <section v-if="errored">
      <p>Ничего не найдено!</p>
    </section>
    <section class="handbook__item" v-else>
      <div class="load-info" v-if="loading">
        <div class="loader" title="loading">
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            style="enable-background: new 0 0 50 50"
            xml:space="preserve"
          >
            <path
              fill="#000"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      </div>

      <div
        class="handbook-info"
        v-else
        v-for="item in referenceBooksInfo"
        v-bind:key="item.id"
      >
        <div class="handbook-info__wrapper">
          <HandBookCard :HandBookCard="item" :HandBookCardInfo="item['@id']" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "@/axios/axios.js";
import HandBookCard from "@/components/cards/HandBooksCard.vue";
export default {
  name: "HandbookView",
  components: {
    HandBookCard,
  },
  data() {
    return {
      referenceBooksInfo: {},
      loading: true,
      errored: false,
      show: false,
    };
  },
  created() {
    this.getReferenceBooks();
  },
  methods: {
    getReferenceBooks() {
      axios
        .get("api/reference_books/")
        .then((res) => {
          this.referenceBooksInfo = res.data["hydra:member"];
          console.log(this.referenceBooksInfo)
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.handbook__heading {
  margin: 10px 0;
}
.handbook__item:last-child {
  margin-bottom: 80px;
}
.handbook-info {
  padding: 7px 0;
  border-radius: 12px;
}
.handbook-info__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  margin-left: 5px;
}
.handbook-info__heading {
  margin: 10px 0 0 0;
}
.acive__icon {
  transform: rotate(180deg);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.load-info {
  text-align: center;
}
</style>
