<template>
  <div class="card-list">
    <section class="error" v-show="errored">
      <h1 class="error__message">Извините, информация недоступна</h1>
    </section>
    <div class="load-info" v-if="loading">
      <div class="loader" title="loading">
        <svg
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 50 50"
          style="enable-background: new 0 0 50 50"
          xml:space="preserve"
        >
          <path
            fill="#000"
            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    </div>
    <div class="slider-video swiper" ref="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in products" v-bind:key="item.id">
          <div class="card-list__info-wrapper" @click="currentPlotId = item.id">
         <!--   <h1 class="card-list__heading">Участок №{{ item.id }}</h1> -->
            <h1 class="card-list__text">
              {{ item.title }}
            </h1>
          </div>
        </div>
      </div>
      <div class="swiper-button-arrow swiper-button-prev"></div>
      <div class="swiper-button-arrow swiper-button-next"></div>
    </div>

    <ProductCard :products-info="currentPlot" />
  </div>
</template>

<script>
import ProductCard from "@/components/cards/ProductCard.vue";

import axios from "@/axios/axios.js";

import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";

export default {
  name: "CardList",
  components: {
    ProductCard,
  },
  data() {
    return {
      products: [],
      loading: true,
      errored: false,
      currentPlotId: 1,
    };
  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      modules: [Navigation, Pagination, Autoplay],

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      on: {
        slideChange: (swiper) => {
          this.activeIndex = swiper.realIndex;
          this.currentPlotId = this.products[swiper.realIndex].id;
        },
      },
    });
  },
  computed: {
    currentPlot() {
      return (
        this.products.find((product) => product.id === this.currentPlotId) || {}
      );
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("api/points")
        .then((res) => {
          this.products = res.data["hydra:member"] || [];
          this.products[0].id = this.currentPlotId;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style>
:root {
  --swiper-theme-color: #c6890d;
  --swiper-navigation-size: 19px;
}
.slider-video {
  background: url(../assets/background.svg) no-repeat;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  background-size: 100%;
}
.swiper-slide {
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  cursor: pointer;
}
.card-list__heading {
  margin-bottom: 5px;
}
.card-list__text {
  text-align: center;
  margin-bottom: 0px;

}
.load-info {
  text-align: center;
}
.swiper-button-arrow {
  top: 41% !important;
}
.swiper-button-arrow {
  width: 32px;
  height: 32px;
  background: #C6890D;
border-radius: 45px;
}
.swiper-button-arrow.swiper-button-next:after, .swiper-button-arrow.swiper-button-prev:after{
  content: '';
  display: block;
  position: absolute;
  width: 7px;
  height: 2px;
  background: white;
  transform: rotate(45deg);
  left: 13px;
  top: 13px;
}
.swiper-button-arrow.swiper-button-next:before, .swiper-button-arrow.swiper-button-prev:before {
  content: '';
  display: block;
  position: absolute;
  width: 7px;
  height: 2px;
  background: white;
  transform: rotate(-45deg);
  left: 13px;
  top: 17px;
}
.swiper-button-arrow.swiper-button-prev {
  transform: rotate(180deg)
}
</style>
