<template>
  <div>
    <div class="feed-info" v-for="item in feedInfo" v-bind:key="item.id">
      <FeedItem :feedInfo="item" :openId="openId"/>
    </div>
  </div>
</template>

<script>
import FeedItem from "@/components/cards/FeedItem.vue";
export default {
  data() {
    return {
      showFeed: false,
      openId: 0
    };
  },
  components: {
    FeedItem,
  },
  props: {
    feedInfo: {
      default: {},
    },
  },
    created() {
        if(this.$router.currentRoute.query?.id){
            this.openId = this.$router.currentRoute.query?.id
        }
    }
};
</script>
<style scoped>
.feed-info:first-child {
  margin-top: 20px;
}
.feed-info:last-child {
  margin-bottom: 80px;
}
</style>
