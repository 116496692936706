<template>
  <div class="feed">
    <h1 class="feed__heading">Лента</h1>
    <section v-if="errored">Извините, информация недоступна</section>
    <section v-else class="feed__item">
      <div class="load-info" v-if="loading">
        <div class="loader" title="loading">
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            style="enable-background: new 0 0 50 50"
            xml:space="preserve"
          >
            <path
              fill="#000"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      </div>
      <div v-else class="option">
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          @click="clickTabs(tab)"
          :class="['tab-btn', { active: selected === tab.component }]"
        >
          {{ tab.name }}
        </button>
      </div>
      <component :feedInfo="feedInfo" :is="selected" class="tab"></component>
    </section>
  </div>
</template>

<script>
import Feed from "@/components/tabs/Feed.vue";
import Notifications from "@/components/tabs/Notifications.vue";
import axios from "@/axios/axios.js";
export default {
  name: "FeedView",
  data() {
    return {
      feedInfo: {},
      loading: true,
      errored: false,
      selected: "Feed",
      tabs: [
        {
          name: "Новости",
          component: "Feed",
        },
        {
          name: "Уведомления",
          component: "Notifications",
        },
      ],
    };
  },
  components: {
    Feed,
    Notifications,
  },
  created() {
    if(this.$router.currentRoute.query?.tab){
        this.selected = this.$router.currentRoute.query?.tab

    }
      this.clickTabs(this.tabs.find(item => item.component === this.selected))
  },

  methods: {
    clickTabs(tab) {
      this.selected = tab.component
      if(tab.component === 'Feed') {
        axios
        .get("/api/posts?type=post")
        .then((res) => {
          console.log(res)
          this.feedInfo = res.data["hydra:member"];
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
      } else {
        axios
        .get("/api/posts?type=notify")
        .then((res) => {
          console.log(res)
          this.feedInfo = res.data["hydra:member"];
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
      }
    }
  },
};
</script>

<style scoped>
.feed__heading {
  margin: 10px 0;
}

.load-info {
  text-align: center;
}

.option {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f4;
  border-radius: 19px;
}
.tab-btn {
  padding: 6px 25px;
  cursor: pointer;
  border: none;
  outline: none;
  background: #f4f4f4;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  flex: 1 1 50%;
  margin: 10px 15px;
}

.active {
  background: white;
  border-radius: 12px;
}
</style>
