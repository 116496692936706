<template>
  <div class="email">
    <section class="error" v-if="errored">
      <p class="error__text">Ничего не найдено!</p>
    </section>
    <section class="email__item" v-else>
      <div class="load-info" v-if="loading">
        <div class="loader" title="loading">
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            style="enable-background: new 0 0 50 50"
            xml:space="preserve"
          >
            <path
              fill="#000"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      </div>

      <div class="email-info" v-else>
        <div class="email-info__wrapper">
          <p class="email-info__heading">Электронная почта</p>
          <p class="email-info__text">{{ emailInfo.value }}</p>
        </div>
        <a class="email-info__link" target="_blank" :href="`mailto:${emailInfo.value}`"></a>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "@/axios/axios.js";
export default {
  name: "EmailInfo",
  data() {
    return {
      emailInfo: {},
      loading: true,
      errored: false,
    };
  },
  created() {
    this.getEmailInfo();
  },
  methods: {
    getEmailInfo() {
      axios
        .get("api/key_values/1")
        .then((res) => {
          this.emailInfo = res.data;
        })
        .catch((error) => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.email {
  border: 2px solid #f6f6f6;
  border-radius: 12px;
  margin-top: 20px;
}
.email-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.email-info__wrapper {
  margin-left: 10px;
}
.email-info__heading {
  color: #828991;
}
.email-info__text {
  font-weight: bold;
}
.email-info__link {
  display: block;
  background-color: #ffbe37;
  background-image: url(../../assets/email.svg);
  background-repeat: no-repeat;
  height: 44px;
  width: 44px;
  margin-right: 10px;
  border-radius: 18px;
}
.error__text {
  margin: 5px;
}
.load-info__text {
  margin: 10px;
}
.load-info {
  text-align: center;
}
</style>
