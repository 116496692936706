<template>
  <div class="handBookCard">
    <div class="handBookCard__img" v-on:click="show = !show">
      <h3 class="handbook-info__heading">
        {{ HandBookCard.title }}
        <svg
          v-if="!show"
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 7L7 1L1 7" stroke="#C6890D" />
        </svg>
        <svg
          v-if="show"
          class="acive__icon"
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 7L7 1L1 7" stroke="#C6890D" />
        </svg>
      </h3>
      <div
        class="handbook-info__description"
        v-html="`${HandBookCard?.short_description}`"
        v-if="!show && HandBookCard?.short_description"
      ></div>
      <div v-show="show">
        <div
          class="handbook-info__description"
          v-html="`${HandBookCard?.decription}`"
          v-if="HandBookCard?.decription"
        ></div>
        <img
          v-if="!HandBookCard.longitude && currBookInfoCont?.file?.path"
          class="handBookCard__image"
          :src="`https://sibcoalapi.jslab.ru/${currBookInfoCont?.file?.path}`"
          alt="handbook image"
        />
        <div v-if="HandBookCard.longitude">
          <yandex-map
  :coords="[HandBookCard.latitude, HandBookCard.longitude]"
  zoom="10"
  style="width: 100%; height: 300px;"

>

    <ymap-marker
      marker-id="1"
      marker-type="placemark"
      :coords="[HandBookCard.latitude, HandBookCard.longitude]"
      hint-content="Hint content 1"
      :balloon="{header: 'header', body: 'body', footer: 'footer'}"
      :icon="{color: 'green'}"
      cluster-name="1"
    ></ymap-marker>

</yandex-map>
        <!--  <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d92694.72508260954!2d54.87383850691958!3d65.01082216038377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNjXCsDAwJzAwLjAiTiA1NcKwMDAnMDAuMCJF!5e0!3m2!1sru!2sru!4v1675002561897!5m2!1sru!2sru"
            width="100%"
            height="350"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>  -->
          <!-- <p>longitude:{{ HandBookCard.longitude }}</p>
          <p>latitude:{{ HandBookCard.latitude }}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/axios.js";
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
export default {
  components: { yandexMap, ymapMarker  },
  name: "HandBookCard",
  data() {
    return {
      currBookInfo: {},
      loading: true,
      errored: false,
      show: false,
    };
  },
  props: {
    HandBookCardInfo: "",
    HandBookCard: {
      default: () => {},
      type: Object,
    },
  },
  created() {
    this.getHandBookCardInfo();
  },
  methods: {
    getHandBookCardInfo() {
      console.log(this.HandBookCardInfo)
      axios
        .get(`${this.HandBookCardInfo}`, {
          headers: {

    "Accept": "application/json"
  },
        })
        .then((res) => {
          this.currBookInfo = res.data;
          console.log(this.currBookInfo)
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    currBookInfoCont() {
      console.log(this.currBookInfo)
      return this.currBookInfo
    }
  }
};
</script>

<style scoped>
.load-info__text {
  margin: 10px;
}
.handBookCard__image {
  width: auto;
  display: block;
  margin: 20px auto;
}
.handbook__heading {
  margin: 10px 0;
}
.handbook__item:last-child {
  margin-bottom: 80px;
}
.handbook-info {
  border: 2px solid #f6f6f6;
  border-radius: 12px;
}
.handbook-info__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  margin-left: 5px;
}
.handbook-info__heading {
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
svg {
  padding: 10px;
}
.acive__icon {
  transform: rotate(180deg);
}
</style>
